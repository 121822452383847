import axios from 'axios';
import Helmet from 'react-helmet';
import { useContext } from 'react';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import PageHeader from '../layout/PageHeader';
import DashboardInner from '../../layouts/DashBoardInner';
import { Box, CircularProgress } from '@material-ui/core';
import { TableContext } from '../../contexts/TableContext';
import initiateExportGeneration from '../../utils/initiateExportGeneration';

const PageContent = styled.div`
  padding-left: 48px;
  padding-right: 48px;
`;

function PageTemplate(props) {
  const context = useContext(TableContext);
  const [isLoading, setLoading] = useState(true);
  const [editID, setEditID] = React.useState(null);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [exportRetryCount, setExportRetryCount] = useState(0);

  function updateLoad(loading) {
    setLoading(loading);
  }

  function OpenRecord(id) {
    setEditID(id);
  }

  async function checkForExport(link) {
    while (exportRetryCount < 100) {
      try {
        let headRes = await axios.head(link);
        return headRes;
      } catch (error) {
        setExportRetryCount(exportRetryCount + 1);
        await checkForExport(link);
      }
    }
  }

  async function GenerateExport(props) {
    setLoading(true);
    const exportRes = await initiateExportGeneration(props, context);
    if (exportRes.data[props.data.export.apiCall].result == true) {
      const exportFileLink = exportRes.data[props.data.export.apiCall].exportFileLink;
      const fileCheck = await checkForExport(exportFileLink);
      if (fileCheck.status == 200) {
        const a = document.createElement('a');
        a.href = exportFileLink;
        a.download = exportFileLink.split('/').pop();
        a.click();
      } else {
        window.alert('Er ging iets fout op de server. Probeert opnieuw.');
      }
    } else {
      window.alert('Er ging iets fout op de server. Probeert opnieuw.');
    }
    setLoading(false);
    setExportRetryCount(0);
  }

  function Refresh() {
    console.log('Refresh!');
    setRefreshCounter(refreshCounter + 1);
  }

  const extraProps = {};
  extraProps.Refresh = Refresh;
  extraProps.OpenRecord = OpenRecord;
  extraProps.GenerateExport = GenerateExport;
  extraProps.RefreshCounter = refreshCounter;

  const innerComponent = (
    <props.innerComponent key="pagetemplate1" onLoaded={updateLoad} data={props.data} {...extraProps} editID={editID}></props.innerComponent>
  );

  return (
    <DashboardInner>
      {isLoading ? (
        <Box display="flex" justifyContent="center" my={6}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Helmet title={props.title} />
          <PageHeader
            title={props.title}
            variant={props.headerTemplate}
            headerAction={props.headerAction}
            location={props.location}
            data={props.data}
            {...extraProps}
          />
        </>
      )}
      <PageContent>{innerComponent}</PageContent>
    </DashboardInner>
  );
}

export default PageTemplate;
